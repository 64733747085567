import React from 'react';
import './HowItWorks.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowRightLong, faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function HowItWorks({ isMobile, onSignup }) {

    return (
        <section id="how-it-works">
            <section className={cx('how', {
                'mobile': isMobile
            })}>
                <h1 className="headline">How it works</h1>
                <div className={cx('how-steps', {
                    'mobile': isMobile
                })}>
                    <div className="how-step">
                        <div className="step-number">1</div>
                        <h3 className="step-title">Priced</h3>
                        <div className="step-description">
                            To sell your tickets, we start by pricing them accurately. Utilize Lysted's state-of-the-art pricing tools and historical data to net you top dollar for your tickets. We stay up-to-date on market conditions to ensure you are never underpriced. Pricing resell tickets is easier than ever before.                        </div>
                        </div>
                    <div className="how-step step-arrow">
                        {isMobile ? (
                            <FontAwesomeIcon icon={faArrowDownLong} />
                        ) : (
                                <FontAwesomeIcon icon={faArrowRightLong} />
                        )}
                    </div>
                    <div className="how-step">
                        <div className="step-number">2</div>
                        <h3 className="step-title">Placed</h3>
                        <div className="step-description">
                            There are over two thousand ticket websites and apps that can sell your tickets. With Lysted, your tickets will be available on them all. The moment they sell on one of the sites, they're instantly removed elsewhere to avoid double selling. Save time removing your tickets for sale on each site, and work with Lysted to automate the process.                        </div>
                        </div>
                    <div className="how-step step-arrow">
                        {isMobile ? (
                            <FontAwesomeIcon icon={faArrowDownLong} />
                        ) : (
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        )}
                    </div>
                    <div className="how-step">
                        <div className="step-number">3</div>
                        <h3 className="step-title">Paid</h3>
                        <div className="step-description">
                            Lysted will securely distribute your funds via bank deposit a few weeks after tickets are delivered to buyers. Waiting months until the event takes place to receive your payment is a thing of the past. With faster payments, you can resell tickets with financial peace of mind.                        </div>
                        </div>
                </div>
            </section>
            <section className={cx('how solver', {
                'mobile': isMobile
            })}>
                <div className={cx('how-problem', {
                    'mobile': isMobile
                })}>
                    <div className="how-problem-content">
                        We<span>solve</span>traditional ticket-selling hassles
                    </div>
                </div>
            </section>
            <section className={cx('how', {
                'mobile': isMobile
            })}>
                <div className={cx('how-content', {
                    'mobile': isMobile
                })}>
                    <div className="how-item left">
                        <h3 className="how-solution">
                            Your problems
                        </h3>
                        <div className="how-problem-container">
                            <span className="how-problem-item problem">Slow payment – Tired of waiting months for event payouts?</span>
                            <span className="how-problem-item problem">Tedious posting – Don’t have time to spend hours manually updating prices?</span>
                            <span className="how-problem-item problem">Lost revenue – Have you lost money on expired tickets?</span>
                            <span className="how-problem-item problem">Wasted time – Done with wasting time manually listing each ticket?</span>
                            <span className="how-problem-item problem">Unsure about competitive pricing?</span>
                        </div>
                        <h4 className="how-solution-ready">
                            Ready to get started?
                        </h4>
                        <div className="how-solution-ready-sub">List your tickets for free and earn more with reduced fees while receiving swift, secure payments directly to your bank account.</div>
                        <div className="btn arrow how-cta" onClick={onSignup}>
                            {'Sign up'}
                            <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                        </div>
                    </div>
                    <div className="how-item right">
                        <h3 className="how-solution">
                            Our solutions
                        </h3>
                        <div className="how-problem-container">
                            <span className="how-problem-item">Timely payment – We send payments a few weeks after tickets are delivered to buyers, so you get your money faster than ever before.</span>
                            <span className="how-problem-item">Automated posting – Post your tickets once through Lysted, and we'll list them everywhere else. Our automated listing software makes individual ticket posting a thing of the past.</span>
                            <span className="how-problem-item">Better sales – Lysted boosts exposure by listing tickets across all major marketplaces simultaneously, enhancing your sales potential.</span>
                            <span className="how-problem-item">Always fair pricing – Lysted automates pricing for you and takes the hassle out of choosing fair prices.</span>
                            <span className="how-problem-item">Better rates – List your tickets for free. Once they sell, you will benefit from competitive rates that we've earned over years of partnership with the major ticket exchanges.</span>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default HowItWorks;
